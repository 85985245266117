.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 350px) {
    width: 290px;
  }
  @media screen and (max-width: 360px) {
    width: 300px;
  }
  @media screen and (max-width: 370px) {
    width: 310px;
  }
  width: 320px;
}

.heading {
  color: #222222;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.airportLable {
  color: #222222;
  font-family: Lato;
  font-size: 11px;
  font-family: "Lato", sans-serif;
}

.formControlContainer{
    padding-left: 18px !important;
}

.icon {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #ffffff;
  outline-offset: 2px;
  outline: 1px solid #c1c1c1;
}
.checkedIcon {
  outline: 1px solid #c1c1c1;
  outline-offset: 2px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #0B3353;
}
