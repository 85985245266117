.container {
    justify-self: center;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    // gap: 5px;
    position: relative;
    .systemChatIcon{
      display: flex;
      gap: 6px;
      align-items: center;
    }
    .chatIcon {
      width: 32px;
    }
    .heading {
      color: #222222;
      font-family: Lato;
      font-size: 13px;
      // font-weight: 600;
      white-space: pre-wrap;
      // padding: 0 20px;

    }
    .para {
      align-self: flex-start;
      color: #222222;
      font-family: Lato;
      font-size: 14px;
      // margin-left: 42px;
      white-space: pre-wrap;
    }
  }
  
  .markdown {
    // padding-left: 20px;
    // padding-left: 30px;
    color: #222222;
    font-family: Lato;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    padding-left: 40px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
    @media only screen and (max-width: 600px) {
      margin-bottom: 0px;
    }
    // white-space: text-wrap;
  }
  .markdown li {
    margin-left: 16px;
  }
 