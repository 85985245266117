.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    width: 100%;
    min-height: calc(100dvh - 60px);
    overflow: auto;
    .titleCard {
      padding-left: 38px;
      padding-top: 10px;
      .bookingTitle {
        color: #696969;
        font-size: 20px;
        line-height: 24px;
        font-family: lato;
        font-weight: 600;
        text-align: left;
        margin-bottom: 25px;
      }
      .checkCard {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        height: 50px;
        overflow-x: auto;
        scrollbar-width: thin;
        .checkBox {
          display: flex;
          align-items: center;
          gap: 20px;
          .inputCheckBox {
            width: 20px;
            height: 20px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          .label {
            color: #696969;
            font-size: 10px;
            line-height: 12px;
            font-weight: 500;
            letter-spacing: 0.2px;
            text-align: left;
            text-transform: uppercase;
          }
        }
        .inputCheckBox:checked {
          background-color: transparent;
        }
        .searchCard {
          margin-top: -15px;
          display: flex;
          gap: 15px;
          @media only screen and (max-width: 600px){
            padding-right: 10px;
          }
          .travelTitle {
            color: #222222 !important;
          }
        }
      }
    }
    .detailsCard {
      padding-left: 2px;
      padding-right: 2px;
    }
    .divider {
      width: 100%;
      border-bottom: 1.1px solid #1D75BB;
    }
    .list {
      position: relative;
  
      .listCard {
        position: relative;
      
        .showCard,
        .packageShowMoreCard {
          display: flex;
          text-align: center;
          justify-content: center;
 
          .showMoreBtn {
            color: #696969;
            font-weight: 600;
            font-family: lato;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.2px;
            .showMoreIcon {
              width: auto;
              height: 20px;
              cursor: pointer;
            }
          }
          .showMoreBtn:hover {
            outline: none;
            background: none !important;
          }
        }
        .showCard {
          height: 80px;
        }
        .packageShowMoreCard {
          height: 50px;
        }
      }
      .buttonCard {
        margin-top: 150px;
        margin-bottom: 100px;
      }
    }

    .flightListCard {
      position: relative;

      .flightListBox {
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: thin;
        padding-left: 2px;
        padding-right: 2px;
      }
      .showCard {
        display: flex;
        text-align: center;
        justify-content: center;
        height: 80px;
        .showMoreBtn {
          color: #696969;
          font-weight: 600;
          font-family: lato;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.2px;
          .showMoreIcon {
            width: auto;
            height: 20px;
            cursor: pointer;
          }
        }
        .showMoreBtn:hover {
          outline: none;
          background: none !important;
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
