.mainContainer {
  box-sizing: border-box;
  border: 1px solid #979797;
  border-radius: 4px;
  background-color: #fdfdfd;
  box-shadow: 0 0.6px 1px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  padding: 15px;
  padding-bottom: 80px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 350px) {
    max-width: 340px;
  }
  @media screen and (max-width: 360px) {
    max-width: 350px;
    
  }
  @media screen and (max-width: 370px) {
    max-width: 360px;
  }
  max-width: 370px;
}
.anchorElement {
//   background-color: blue;
}
.button {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  background-color: #fcb400 !important;
  color: #222222 !important;
  font-weight: 600 !important;
  font-family: "Lato" !important;
  font-size: 12px !important;
}
