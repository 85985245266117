.loadContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .systemChatIcon {
    display: flex;
    gap: 10px;
    align-items: center;

    .chatIcon {
      width: 32px;
    }

    .heading {
      color: #222222;
      font-family: Lato;
      font-size: 14px;
      font-weight: 600;
    }
  }

  // .loader {
  //   display: inline-block;
  //   position: relative;
  //   width: 40px;
  //   height: 15px;
  // }

  // .loader span {
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   width: 7px;
  //   height: 7px;
  //   border-radius: 50%;
  //   background: #a4d1f0;
  //   // animation: loader 1.2s ease-in-out infinite;
  //   animation: wave 1.2s ease-in-out infinite;
  // }

  // .loader span:nth-child(1) {
  //   left: 10%;
  //   animation-delay: -0.4s;
  // }

  // .loader span:nth-child(2) {
  //   left: 40%;
  //   animation-delay: -0.2s;
  // }

  // .loader span:nth-child(3) {
  //   left: 70%;
  //   animation-delay: 0s;
  // }

  // @keyframes loader {
  //   0% {
  //     transform: translateY(0);
  //   }

  //   50% {
  //     transform: translateY(-5px);
  //   }

  //   100% {
  //     transform: translateY(0);
  //   }
  // }

  // @keyframes wave {

  //   0%,
  //   100% {
  //     transform: translateY(0);
  //   }

  //   50% {
  //     transform: translateY(-10px);
  //   }
  // }
  .loader {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle {
    width: 15px;
    height: 15px;
    background-color: #D8D8D8;
    border-radius: 50%;
    margin-right: 8px;
    animation: pulse 1.2s ease-in-out infinite;
  }

  .circle:nth-child(2) {
    animation-delay: 0.2s;
    background-color: #D8D8D8;

  }

  .circle:nth-child(3) {
    animation-delay: 0.4s;
    background-color: #D8D8D8;

  }

  .circle:nth-child(4) {
    animation-delay: 0.6s;
    background-color: #D8D8D8;

  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0.7;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0.8);
      opacity: 0.7;
    }
  }
}