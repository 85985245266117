.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #696969;
  .container {
    height: 100px;
    width: 100%;
    padding-left: 38px;
    .title {
      color: #222222;
      font-family: Lato;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.21px;
      line-height: 12px;
      margin-bottom: 8px;
    }
    .contentCard {
      display: flex;
      width: 100%;
      overflow-x: auto;
      scrollbar-width: none;
      gap: 10px;
      .routTitle {
        color: #222222;
      }
      .travelCard {
        display: block;
        margin-top: 10px;
        .buttonTitleStyles {
          color: #222222;
          font-size: 7px;
          line-height: 9px;
          letter-spacing: 0.4px;
          font-weight: 600;
          font-family: lato;
          margin-bottom: 2px;
          text-transform: uppercase;
          margin-left: 2px;
        }
        .buttonStyles {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #979797;
          border-radius: 4px;
          background-color: #f6f6f6;
          padding: 10px 5px;
          color: #222222;
          font-family: Lato;
          font-size: 8px;
          font-weight: bold;
          letter-spacing: 0.4px;
          line-height: 10px;
          cursor: pointer;
        }
        .travelTitle {
          @extend .buttonTitleStyles;
        }
        .travelBtn {
          height: 30px;
          width: 65px;
          @extend .buttonStyles;
          .desc {
            color: #222222;
            font-family: Lato;
            font-size: 6px;
            font-weight: bold;
            letter-spacing: 0.15px;
            line-height: 7.5px;
            text-align: center;
          }
          .rooms{
            text-align: center;
          }
        }
      }
      .travelTitle {
        color: #222222;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
