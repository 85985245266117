.travelCard {
    display: block;
    margin-top: 10px;
    .buttonTitleStyles {
        color: #ffffff;
        font-size: 7px;
        line-height: 9px;
        letter-spacing: 0.4px;
        font-weight: 600;
        font-family: lato;
        margin-bottom: 2px;
        text-transform: uppercase;
        margin-left: 2px;
      }
      .buttonStyles {
        height: 30px;
        width: max-content;
        display: flex;
        align-items: center;
        border: 1px solid #979797;
        border-radius: 4px;
        background-color: #f6f6f6;
        padding: 10px 5px;
        color: #222222;
        font-family: Lato;
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 10px;
        cursor: pointer;
      }
    .travelTitle {
      @extend .buttonTitleStyles;
    }
    .travelBtn {
      @extend .buttonStyles;
      .arrowIcon {
        width: 10px;
        height: 10px;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;
      }
      .inputField {
        width: 66px;
        outline: none;
        border: none;
        color: #222222;
        font-family: Lato;
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 10px;
        cursor: pointer;
        background-color: transparent
      }
    }
    .searchTitle {
      @extend .buttonTitleStyles;
      visibility: hidden;
    }
    .searchBtn {
      background-color: #fcb400 !important;
      @extend .buttonStyles;
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px !important;
      outline: none;
      border: none;
      box-shadow: none;
      .searchIcon {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }