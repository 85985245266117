.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // padding-top: 50px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 767px) {
    background-color: #ffff;
    padding-bottom: 200px;
    height: 100vh;
    overflow: auto;
  }
  .reOrderCard {
    margin-bottom: -25px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
    padding-top: 20px;
    .reOrder {
      font-size: 9px;
      font-family: Lato;
      color: #222222;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0px;
    }
  }

  .unSelectedTrips {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding: 20px;
    padding-left: 35px;
    // background-color: white;
    // opacity: 0.5;
    margin-right: 50px;
  }
  .budget {
    display: block;
    position: relative;
    padding: 2vh 0vh 4vh 4vh;
  }
  .dividerCard {
    display: block;
    position: relative;
    padding-left: 4vh;
    padding-right: 4vh;
    .line {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  .saveBtn {
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 5px 15px;
    border: none;
    box-shadow: none;
    border-radius: 50px;
    background-color: #ffce07;
    color: #222222;
    font-weight: 800;
    font-family: Lato;
    text-transform: capitalize;
    align-self: center;
    justify-self: center;
    font-size: 10px;
  }
  .saveBtn:hover {
    background-color: #ffce07;
  }
  .cancelBtn {
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 4px 10px;
    border-radius: 50px;
    color: #222222;
    border-color: #222222;
    font-weight: 800;
    font-family: Lato;
    text-transform: capitalize;
    align-self: center;
    justify-self: center;
    font-size: 10px;
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.infoTextContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  text-align: center;
  & h3 {
    padding: 0 80px;
    font-size: 15px;
    line-height: 16px;
  }
  & h1 {
    width: 350px;
    padding: 0 55px;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.adjustTrip {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  left: 0;
  right: 0;
  // padding: 20px;
  padding-top: 20px;
  padding-left: 20px;
  .destinationCard {
    flex-grow: 1;
    // margin-right: 40px;
  }
}
