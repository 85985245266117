.mainContainer {
  padding: 0 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  @media screen and (max-width: 350px) {
    width: 290px;
  }
  @media screen and (max-width: 360px) {
    width: 300px;
  }
  @media screen and (max-width: 370px) {
    width: 310px;
  }
  width: 320px;
  .counterTitleContainer {
    display: flex;
    flex-direction: column;
    & h1 {
      color: #696969;
      font-size: 12px;
      font-family: "Lato", sans-serif;
    }
    & p {
      color: #7cbeea;
      font-size: 8px;
      font-family: "Lato", sans-serif;
    }
  }
}

.counterContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;
  .button {
    box-sizing: border-box;
    height: 22px;
    width: 30px;
    border: 1px solid #979797;
    border-radius: 3px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 14px;
    }
  }
  & h1 {
    box-sizing: border-box;
    height: 23px;
    width: 23px;
    border: 1px solid #979797;
    background-color: #d8efff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family: "Lato", sans-serif;
  }
}
