.main {
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 40px;
    .container {
        border-bottom: 1px solid #979797;
        min-height: 80px;
        padding: 10px 20px 10px 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        .optionIcon {
            width: 25px;
            height: 25px;
        }
        .optionsCard {
            display: flex;
            align-self: center;
            justify-content: space-between;
            width: 100%;
            gap: 30px;
            .descCard {
                display: block;
                .tripTitle {
                    color: #696969;
                    font-size: 14px;
                    line-height: 14px;
                    word-spacing: 4px;
                    text-align: left;
                    font-family: Lato;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                .tripDesc {
                    color: #8F8F8F;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    margin-top: 2px;
                    font-family: lato;
                    font-weight: 500;
                }
            }
            .arrowCard {
                padding-top: 5px;
                padding-left: 5px;
                .arrowIcon {
                    width: auto;
                    height: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}
.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}