.hotelDetailsContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;
  // z-index: 999;
  padding-top: 15px;
  padding-bottom: 100px;
  padding-bottom: 100px;
  @media screen and (min-width: 700px) {
    height: 100%;
    overflow: auto;
    // padding-top: 75px;
  }
  .closeBtnContainer {
    margin-left: 15px;
    margin-right: 13px;
    display: flex;
    margin-bottom: 1.698vh;
    justify-content: space-between;
    .columnAlignButton {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: max-content;
      font-size: 12px !important;
      // transform: translateY(10px);
      font-weight: 700 !important;
      color: #696969 !important;
      font-family: "Lato", sans-serif !important;
      gap: 6px;
      & img {
        width: 24px;
      }
    }
    .priceContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & h1 {
        font-size: 11px;
        font-weight: 600;
      }
      .price {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .horizontalScrollContainer {
    margin-bottom: 2.648vw;

    @media screen and (min-width: 768px) {
      margin-bottom: 13.2px;
    }
  }
  .hotelDescriptionContainer {
    padding-left: 5.062vw;
    margin-bottom: 15px;
    @media screen and (min-width: 768px) {
      padding-left: 25.3px;
    }
    .contentCard {
      position: relative;
      display: block;
      // margin-top: -8px;
      // min-width: 130px;
      // max-width: 100%;
      .starContainer {
        display: flex;
        align-items: center;
        gap: 0.736vw;
        @media screen and (min-width: 768px) {
          gap: 3.68px;
        }

        .starPropertyImg {
          width: 2.726vw;
          @media screen and (min-width: 768px) {
            width: 16.35px;
          }
        }
        .star {
          color: #272727;
          font-size: 1.8vw;
          // line-height: 0.623vw;
          letter-spacing: 0.037vw;
          text-align: left;
          font-family: lato;
          font-weight: 500;

          @media screen and (min-width: 768px) {
            font-size: 10.8px;
            letter-spacing: 0.221px;
          }
        }
      }

      .hotelName {
        color: #252525;
        font-family: lato;
        font-size: 3.972vw;
        line-height: 3.894vw;
        font-weight: bold;
        letter-spacing: 0.05vw;
        margin-top: 1.877vw;
        // width: 130px;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          letter-spacing: 0px;
          margin-top: 11.26px;
          line-height: 23px;
        }
      }
      .location {
        color: #252525;
        font-family: Lato;
        font-size: 2.336vw;
        letter-spacing: 0.085vw;
        line-height: 3.894vw;
        // margin-top: -4px;
        margin-bottom: 3vw;
        @media screen and (min-width: 768px) {
          font-size: 18px;
          letter-spacing: 0px;
          margin-bottom: 18px;
          line-height: 23px;
        }
      }
      .landMark {
        color: #252525;
        font-family: Lato;
        font-size: 2.336vw;
        font-weight: bold;
        letter-spacing: 0.05vw;
        line-height: 36px;
        line-height: 2.804vw;
        margin-bottom: 40px;
        margin-bottom: 3vw;
        @media screen and (min-width: 768px) {
          font-size: 17px;
          letter-spacing: 0px;
          margin-bottom: 18px;
          line-height: 15px;
        }
      }

      .ratingsSection {
        align-items: center;
        gap: 20px;
        gap: 1.558vw;
        @media screen and (min-width: 768px) {
          gap: 9.3px;
        }
        .ratingCardClass {
          //         height: 75px;
          // width: 87px;
          // border-radius: 4px;
          // background-color: #5856D6;
          width: 6.776vw;
          height: 5.841vw;
          border-radius: 0.312vw;
          @media screen and (min-width: 768px) {
            width: 25.65px;
            height: 24.046px;
            border-radius: 1.8px;
          }

          .ratingClass {
            font-size: 36px;
            font-size: 2.804vw;
            font-weight: bold;
            letter-spacing: 0.77px;
            letter-spacing: 0.06vw;
            line-height: 44px;
            line-height: 3.427vw;
            @media screen and (min-width: 768px) {
              font-size: 13px;
              letter-spacing: 0;
              line-height: 15px;
            }
          }
        }
        .contentClass {
          .reviewTitleClass {
            font-size: 30px;
            font-size: 2.336vw;
            letter-spacing: 0.64px;
            letter-spacing: 0.05vw;
            line-height: 36px;
            line-height: 2.804vw;
            @media screen and (min-width: 768px) {
              font-size: 16px;
              letter-spacing: 0;
              line-height: 15px;
            }
          }

          .reviewClass {
            color: #7cbeea;
            font-size: 24px;
            font-size: 1.869vw;
            letter-spacing: 0.51px;
            letter-spacing: 0.04vw;
            line-height: 29px;
            line-height: 2.259vw;

            @media screen and (min-width: 768px) {
              cursor: pointer;
              font-size: 12.5px;
              letter-spacing: 0;
              line-height: 13px;
            }
          }
        }
        // background-color: pink;
      }
    }
  }
  .customStyles {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    // margin-top: 0;

    .summaryClass {
      // padding-left: 40px !important;
      margin: 0 !important;
      padding-left: 3.115vw !important;
      // padding-right: 86px !important;
      padding-right: 6.698vw !important;

      @media screen and (min-width: 768px) {
        padding-left: 18.69px !important;
        padding-right: 40.186px !important;
      }

      .heading {
        // font-family: Lato;
        // font-size: 36px;
        font-size: 2.804vw;
        font-weight: bold;
        letter-spacing: 0;
        // line-height: 42px;
        line-height: 3.271vw;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }

    .accordianDetailsClass {
      padding-bottom: 0;
    }
  }
}

.Mui-expanded {
  margin-top: 0 !important;
}
