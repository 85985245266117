.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fafafa;
  width: 100%;
  overflow-x: hidden;

  @media only screen and (max-width: 767px) {
    width: 100vw;
  }

  @media only screen and (min-width: 767px) {
    background-color: #ffff;
    padding-left: 10px;
  }

  .loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    padding-top: 2vh;
    padding-left: 10px;
    overflow: auto;
    width: 100%;
    // height: 100vh;
    background: transparent;
    overflow-x: hidden;

    .titleCard {
      position: relative;
      padding-left: 1vh;
      .titleSubCard {
        display: flex;
        align-items: center;
        gap: 80px;
        .closeCard {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          cursor: pointer;
          .closeIcon {
            height: 1.2em;
            width: auto;
          }
          .closeText {
            color: #696969;
            font-size: 10px;
            font-family: lato, sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.5px;
          }
        }
        .edit {
          color: #696969;
          font-size: 18px;
          line-height: 1.2;
          font-weight: 600;
          font-family: Lato;
        }
      }
    }
    .cityCard {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1vh 2vh 0vh 3vh;
      // min-height: 100px;
      @media only screen and (max-width: 767px) {
        min-height: 10vh;
        margin-bottom: 2vh;
      }
      @media only screen and (max-width: 600px) {
        padding: 1vh 2vh 0vh 3vh;
      }
      @media only screen and (min-width: 767px) {
        padding: 0vh 2vh 0vh 3vh;
      }

      .cityBox {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 2px;
        .cityDiv {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          gap: 8px;
          .myCity {
            font-size: 16px;
            line-height: 1.2;
            font-weight: 500;
            font-family: Lato;
            letter-spacing: 0.5px;
            margin-top: 10px;
            color: #696969;
            height: 40px;
          }
          .city {
            margin-left: 10px;
            width: 175px;
            height: 40px;
            padding-top: 10px;
            font-size: 16px;
            line-height: 1.2;
            color: #696969;
            font-weight: 600;
            font-family: Lato;
            letter-spacing: 0.5px;
          }
        }
        .editBox {
          margin-bottom: -10px;
        }
      }
    }

    .climate {
      padding: 2vh 2vh 0vh 3vh;
      // padding-left: 2vh;
    }

    .exp {
      padding: 2vh 2vh 0vh 3vh;
    }

    .dividerCard {
      display: block;
      position: relative;
      padding: 0vh 2vh 0vh 3vh;

      .line {
        border-bottom: 1px solid #d8d8d8;
      }
    }

    .budget {
      padding: 0vh 2vh 2vh 3vh;
      position: relative;
    }

    .editAct {
      padding: 2vh;
      position: relative;
      border-bottom: 1px solid #d8d8d8;
    }

    .accomodation {
      padding: 2vh 2vh 2vh 3vh;
      position: relative;
    }

    .slider {
      padding: 2vh 0vh 4vh 2vh;
      border-bottom: 1px solid #d8d8d8;

      .vacationDuration {
        color: #696969;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 50px;
      }
    }

    .saveCard {
      padding-bottom: 20vh;
      padding-top: 5vh;
      padding-right: 30px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      .saveBtn {
        background-color: #ffce07;
        color: black;
        font-size: 16px;
        font-weight: 700 !important;
        box-shadow: none;
        border-radius: 50px;
        padding-left: 15px;
        padding-right: 15px;
        text-transform: none;
        font-family: Lato;
        letter-spacing: 0.5px;
      }

      .skipBtn {
        display: none;
        background-color: transparent;
        color: black;
        font-weight: 600 !important;
        box-shadow: none;
        border-radius: 50px;
        padding-left: 25px;
        padding-right: 25px;
        text-transform: none;
        font-family: 600;
        font-family: Lato;
        border: 1px solid #979797;
      }
    }
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
