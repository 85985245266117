.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #696969;
    background-color: #f4f4f4;
    margin-top: 20px;
    display: flex;
    // flex-wrap: wrap;
    gap: 10px;
    overflow-x: auto;
    scrollbar-width: thin;
    .card1 {
      width: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .detail {
        font-size: 10px;
        color: #222222;
        line-height: 12px;
        letter-spacing: 0.2px;
        text-align: left;
        font-weight: bold;
        font-family: lato;
      }
    }
    .travelerStyles {
      font-size: 8px;
      line-height: 14px;
      color: #272727;
      font-family: lato;
      font-weight: bold;
    }
    .card2 {
      width: 200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .travelerInfo {
        @extend .travelerStyles;
      }
    }
    .card3 {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      .flightDetails {
        @extend .travelerStyles;
      }
    }
    .card4 {
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      .expandIcon {
        width: 14px;
        height: 8px;
        cursor: pointer;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
