.multipleCityContainer{
  margin: 0 -12px;
  margin-top: 10px;
}
.container {
  justify-self: center;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;

  cursor: pointer;
  @media screen and (max-width: 700px) {
    cursor: default;
  }

  .systemChatIcon {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .chatIcon {
    width: 32px;
  }
  .heading {
    color: #222222;
    font-family: Lato;
    font-size: 13px;
    // font-weight: 600;
    // white-space: pre-wrap;
    padding-right: 20px;
  }
  .button {
    color: #222222;
    font-family: Lato;
    font-size: 14px;
    text-transform: lowercase;
    // text-decoration: underline;
    color: #27a9e0;
    font-weight: 600;
    cursor: pointer;
  }
  .para {
    align-self: flex-start;
    color: #222222;
    font-family: Lato;
    font-size: 14px;
    // margin-left: 42px;
    white-space: pre-wrap;
  }
}

.postText {
  color: #222222;
  font-family: Lato;
  font-size: 14px;
  // font-weight: 600;
  // white-space: pre-wrap;
  line-height: 21px;
  padding-left: 40px;
  padding-right: 20px;
}
