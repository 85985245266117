.customCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
  .label {
    color: #696969;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
  }
  .inputBox {
    position: relative;
    .customCheckbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1.5px solid #696969;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .customCheckbox:checked ~ .checkmark {
      background-color: transparent;
    }

    .customCheckbox:checked ~ .checkmark::after {
      display: block;
    }
    .checkmark::after {
      content: "";
      width: 3px;
      height: 8px;
      margin-top: -2px;
      border: solid #696969;
      border-width: 0 1.5px 2px 0;
      transform: rotate(45deg);
      display: none;
      cursor: pointer;
    }
  }
}
