.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: 20px;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  @media only screen and (min-width: 767px) {
    background-color: #ffff;
    padding-bottom: 200px;
    height: 100vh;
    overflow: auto;
  }
  position: relative;
  // margin-top: 150px;
  // z-index: 3;
  .head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 2vh;
    padding-right: 2vh;

    .titleCard {
      margin-top: 5px;
      position: relative;
      .titleBox {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
        .blueIcon {
          width: auto;
          height: 4vh;
          cursor: pointer;
          margin-top: -5px;
          @media only screen and (min-width: 767px) {
           margin-top: 0px;
          }
        }
        .headerCard {
          position: relative;
          display: block;
          .location {
            margin: -5px;
            color: #696969;
            font-family: Lato;
            font-weight: 600;
            font-size: 18px;
            width: 100%;
            padding: 5px;
            border-color: #7cbeea;
          }
          .location:disabled {
            // padding: 0px;
            background-color: transparent;
            border-color: transparent;
            outline-color: transparent;
          }
          .location:focus{
            border-color: #7cbeea;
            outline-color: #7cbeea;
          }
          .duration {
            font-size: 12px;
            font-family: Lato;
            font-weight: 600;
            text-transform: uppercase;
            color: #272727;
            letter-spacing: 0px;
            .days {
              color: #7cbeea;
            }
          }
        }
      }
    }
  }

  .reOrderCard {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: 2vh;
    padding-top: 2vh;
    .reOrder {
      font-size: 9px;
      font-family: Lato;
      color: #222222;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0px;
    }
  }
  .adjustTrip {
    display: block;
    position: relative;
    // padding: 20px;
    padding-right: 0;
  }
  .budget {
    display: block;
    position: relative;
    padding-left: 40px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
  .dividerCard {
    display: block;
    position: relative;
    padding-left: 4vh;
    padding-right: 4vh;
    .line {
      border-bottom: 1px solid #d8d8d8;
    }
  }
  .passengerCard {
    padding: 20px 40px;
    .pass {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      color: #696969;
      text-transform: capitalize;
      margin-bottom: 20px;
    }
    .passBox {
      padding-bottom: 10px;
    }
  }
  .btnCard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4vh;
    padding-bottom: 30px;
    .saveBtn {
      padding-left: 2vh;
      padding-right: 2vh;
      border: none;
      box-shadow: none;
      border-radius: 50px;
      background-color: #ffce07;
      color: #222222;
      font-weight: 600;
      font-family: Lato;
      text-transform: capitalize;
    }
    .cancelBtn {
      padding-left: 2vh;
      padding-right: 2vh;
      border: none;
      box-shadow: none;
      border-radius: 50px;
      background-color: transparent;
      font-weight: 600;
      font-family: Lato;
      color: #222222;
      text-transform: capitalize;
      border: 1px solid #a3a9ad;
    }
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.editIcon{
  margin-left: 20px;
  width: 30px;
  height: 30px;
}