.dialogContainer {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.headingContainer {
  & h1 {
    font-size: 20px;
    color: #272727;
    font-weight: 600;
  }
  & p {
    color: #272727;
    font-weight: 600;
    font-size: 13px;
  }
}

.midContainer {
  display: flex;
  gap: 5px;
  flex-direction: column;
  & h1 {
    font-size: 11px;
    color: #5f6a70;
  }
  & p {
    text-align: end;
    font-size: 9px;
    color: #272727;
    font-weight: 600;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .button {
    border-radius: 25px;
    font-size: 12px;
    font-family: "Lato", sans-serif;
    padding: 8px 20px;
    font-weight: 600;
    color: #272727;
  }
  .deleteBtn:hover{
    background-color: #D31E1E;
  }
  .deleteBtn {
    background-color: #D31E1E;
    color:  #FFFFFF;
    flex-grow: 1;
  }
  .saveBtn:hover{
    background-color: #FCB400;
  }
  .saveBtn {
    background-color: #FCB400;
    flex-grow: 1;
  }
}

.input {
  color: #696969;
  width: 100%;
  font-size: 13px;
  border: none;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  outline-color: #a3a9ad;
  outline-width: 1px;
  border-radius: 5px;
  resize: none;
  outline: 1px solid #a3a9ad;
  -webkit-appearance: none;
}
.input::placeholder {
  color: #a3a9ad !important;
}
.input::-webkit-scrollbar {
  width: 0;
}
