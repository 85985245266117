@use "./variables/color" as *;

* {
  padding: 0;
  font-family: "Lato", sans-serif;
  margin: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}

h1 {
  color: $font-color;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

h2 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 12px;
  font-weight: 400;
}

h3 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 12px;
  font-weight: 600;
}

h4 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 16px;
  font-weight: 700;
}

h5 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
h6 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 12px;
  font-weight: 400;
}

input::placeholder {
  font-family: "Lato", sans-serif;
}

input {
  font-family: "Lato", sans-serif;
}

label {
  font-family: "Lato", sans-serif;
}

.test {
  color: #9c9c9c;
}

/** Swipe to delete **/
.rstdi {
  --rstdiHeight: 30px;
  --rstdiTransitionDuration: 250ms;
  --rstdiTranslate: 0px;
  --rstdiIsRtl: 0;
  --rstdiDeleteColor: rgba(252, 58, 48, 1);
  --rstdiDeleteWidth: 75px;
  --rstdiButtonMarginRight: 0px;
  --rstdiButtonMarginLeft: 0px;

  width: auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: var(--rstdiHeight);
  max-height: calc(var(--rstdiHeight) + 10px);
}

.rstdi *,
.rstdi *:before,
.rstdi *:after {
  box-sizing: border-box;
}

.rstdi.deleting {
  transition: all var(--rstdiTransitionDuration) ease-out;
  max-height: 0;
}

.rstdi .content {
  height: 100%;
  width: auto;
  position: relative;
  transform: translateX(var(--rstdiTranslate));
}

.rstdi .content.transition {
  transition: transform var(--rstdiTransitionDuration) ease-out;
}

.rstdi .content.deleting {
  height: 100%;
  width: auto;
  position: relative;
  transform: scale(0) translateX(var(--rstdiTranslate));
}

.rstdi .delete {
  position: absolute;
  right: 10px;
  left: 10px;
  height: 100%;
  top: 6px;
  // background-color: var(--rstdiDeleteColor);
  height: 58px;
  font-weight: 400;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0 5px 5px 0;
}

.rstdi .delete button {
  width: var(--rstdiDeleteWidth);
  transition: margin transform var(--rstdiTransitionDuration) ease-in-out;
  margin-left: var(--rstdiButtonMarginLeft);
  margin-right: var(--rstdiButtonMarginRight);
  text-align: center;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 14px;
  padding-right: 30px;
}

/**
  React calander Date range
**/
.react-calendar {
  margin-top: -8px !important;
  border: none !important;
  font-family: "Lato", sans-serif !important;
}

.react-calendar * {
  font-family: "Lato", sans-serif !important;
  color: #222222 !important;
  font-weight: 600 !important;
}

.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none !important;
}

.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  font-size: 20px;
  border: 1px solid #9c9c9c !important;
  border-left: none !important;
  border-radius: 0 6px 6px 0;
  margin-left: -15px !important;
}
.react-calendar__navigation__next-button {
  font-size: 20px;
  border: 1px solid #9c9c9c !important;
  border-right: none !important;
  border-radius: 6px 0 0 6px;
  margin-right: -15px !important;
}

.react-calendar__tile{
  width: 40px !important;
  height: 47px !important;
  margin-bottom: 8px !important;
}

// today date
.react-calendar__tile--now {
  background-color: transparent !important;
}

.react-calendar__tile--active{
  background-color: #d8efff !important;
  border: 1px solid #d1c6c6 !important;
  // border-right: none !important;
  // border-bottom: none !important;
  border-radius: 50% !important;
}

// selected range or date
.react-calendar__tile--range {
  background-color: #d8efff !important;
  border: 1px solid #d1c6c6 !important;
  // border-right: none !important;
  // border-bottom: none !important;
  border-radius: 4px !important;
  
}

// start
.react-calendar__tile--rangeStart {
  // border-right: none !important;
  // border-bottom: none !important;
  // border-radius: 4px 0 0 4px !important;
}

// end
.react-calendar__tile--rangeEnd {
  // border-left: none !important;
  // border-top: none !important;
  // border-radius: 0 4px 4px 0 !important;
}

// month and year label
.react-calendar__navigation__label__labelText{
  font-weight: 600 !important;
  font-size: 17px;
}