.mainContainer{
    margin-bottom: -20px;
    // @media screen and (max-width: 350px) {
    //     width: 290px;
    //   }
    //   @media screen and (max-width: 360px) {
    //     width: 300px;
    //   }
    //   @media screen and (max-width: 370px) {
    //     width: 310px;
    //   }
    //   width: 320px;
}