.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .container {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        // padding: 0 10px;
        // max-width: 360px;
        width: 100%;
        .userCard {
            display: flex;
            flex-direction: column;
            gap: 3px;
            padding-top: 5px;
            padding-bottom: 5px;

            .avatarBox {
                display: flex;
                gap: 6px;
                align-items: center;

                .userAvatar {
                    width: 32px;
                }

                .userName {
                    color: #222222;
                    font-family: Lato;
                    font-size: 13px;
                    white-space: pre-wrap;
                    font-weight: 600;
                }
            }

            .message {
                align-self: flex-start;
                color: #222222;
                font-family: Lato;
                font-size: 14px;
                // margin-left: 42px;
                white-space: pre-wrap;
                padding-left: 40px;
                padding-right: 20px;
            }
        }
        .zenCard {
            display: flex;
            align-items: center;
            gap: 6px;
            padding-top: 10px;
            .zenAvatar {
                width: 32px;
            }
            .zenTitle {
                color: #222222;
                font-family: Lato;
                font-size: 13px;
                white-space: pre-wrap;
                font-weight: 600;
            }
        }
    }
}

.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
}