.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
    height: 100%;
    .contentCard {
      width: 100%;
      // @media only screen and (min-width: 767px) {
      //   width: max-content;
      // }
      .hotelListCard {
        width: 100%;
        height: 80px;
        border-radius: 2.6px;
        border-top: 1px solid #979797;
        border-bottom: 1px solid #979797;
        border-left: 1px solid #979797;
        border-right: none;
        display: flex;
        background-color: #ffff;
        gap: 10px;
        overflow-x: auto;
        scrollbar-width: thin;
        margin-bottom: 8px;
        overflow-y: hidden;
        .card1 {
        //   padding: 20px 10px;
          padding-left: 1.2vh;
          display: flex;
          align-items: center;
          gap: 14px;
          width: 180px;
          @media only screen and (min-width: 767px){
            width: 200px;
          }
          .flightImage {
            width: 40px;
            height: 40px;
            border: 0.51px solid #979797;
            border-radius: 8px;
            box-sizing: border-box;
          }

          .flightTime {
            color: #696969;
            font-size: 8px;
            line-height: 15px;
            letter-spacing: 0.2px;
            font-weight: 600;
            font-family: lato;
            .supText {
              color: #bf150d;
              margin-left: 2px;
            }
            .flightName {
              font-weight: 500 !important;
            }
          }
        }
        .card2 {
          padding: 1.2vh 0px;
          width: 60px;
          .stops {
            color: #696969;
            font-family: Lato;
            font-size: 8px;
            line-height: 12px;
            letter-spacing: 0.2px;
            font-weight: 600;
            margin-bottom: 5px;
            .stopInfo {
              font-weight: 500;
            }
          }
        }
        .card3 {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 60px;
          .flightBtn {
            color: #696969;
            font-size: 6px;
            line-height: 8px;
            font-weight: 600;
            font-family: Lato;
            .expIcon {
              width: auto;
              height: 10px;
              cursor: pointer;
            }
          }
          .flightBtn:hover {
            background-color: transparent !important;
          }
        }
        .card4 {
          width: 100px;
          height: 100%;
          background-color: #1D75BB;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 8px;
          border-top-right-radius: 2.6px;
          border-bottom-right-radius: 2.6px;
          .flight {
            position: relative;
            .price {
              font-size: 8px;
              line-height: 15px;
              font-weight: 600;
              font-family: lato;
              color: #ffffff;
              letter-spacing: 0.3px;
              text-align: center;
              .flightClass {
                font-weight: 500 !important;
                text-transform: uppercase;
              }
            }
            .bookBtn {
              width: 62px;
              height: 20px;
              border-radius: 30px;
              background-color: #fcb400;
              box-shadow: none;
              border: none;
              outline: none;
              font-size: 8px;
              line-height: 12px;
              // font-size: 6px;
              // line-height: 10px;
              text-align: center;
              color: #222222;
              font-family: Lato;
              font-weight: 600;
              letter-spacing: 0.5px;
              font-family: lato;
            }
          }
          .tourPack {
            .radioControl {
              width: 65px;
              .radioGroup {
                .customRadio {
                  border: 1px solid #696969 !important;
                  background-color: #ffffff;
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                  &.Mui-checked {
                    color: #0b3353;
                  }
                  color: #0b3353;
                  .MuiSvgIcon-root {
                    font-size: 10px;
                  }
                }
                .radio {
                  color: #ffffff;
                  font-family: Lato;
                  font-size: 13.33px;
                  font-weight: bold;
                  letter-spacing: 0.3px;
                  line-height: 14.6px;
                  .flightClass {
                    display: block;
                    color: #FFFFFF;
                    font-family: Lato;
                    font-size: 8px;
                    letter-spacing: 0.2px;
                    line-height: 15px;
                    margin-top: -2px;
                  }
                }
              }
            }
            .radioBtn {
              position: relative;
              width: 65px;
              
            }
          }
          
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
