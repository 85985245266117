.roomDetailsContainer {
  .closeBtnContainer {
    display: flex;
    margin-bottom: 10.37px;
    justify-content: space-between;
    .columnAlignButton {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: max-content;
      font-size: 12px !important;
      // transform: translateY(10px);
      font-weight: 700 !important;
      color: #696969 !important;
      font-family: "Lato", sans-serif !important;
      gap: 6px;
      padding-left: 14px;
      & img {
        width: 24px;
      }
    }
  }

  .header {
    color: #272727;
    font-family: Lato;
    font-size: 17px;
    // font-size: 4.673vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    // line-height: 5.607vw;
    margin-left: 22.75px;
    margin-bottom: 15px;
  }
}
