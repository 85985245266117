.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .container {
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
    height: 100%;
    .contentCard {
      width: 100%;
      // @media only screen and (min-width: 767px) {
      //   width: max-content;
      // }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .hotelListCard {
        width: 100%;
        height: 121px;
        border-radius: 2.6px;
        border-top: 1px solid #979797;
        border-bottom: 1px solid #979797;
        border-left: 1px solid #979797;
        border-right: none;
        display: flex;
        flex-direction: row;
        background-color: #ffff;
        // gap: 10px;
        overflow-x: auto;
        scrollbar-width: thin;
        margin-bottom: 8px;
        overflow-y: hidden;
        .hotelFlight {
          display: block;
          padding-left: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 0px;
          width: 100%;
          .card1 {
            display: flex;
            align-items: center;
            gap: 14px;
            // width: 240px;
            padding-left: 5px;
            margin-bottom: 10px;
            .flightImage {
              width: 40px;
              height: 40px;
              border: 0.51px solid #979797;
              border-radius: 8px;
              box-sizing: border-box;
            }
            .flightInfo {
              display: flex;
              align-items: center;
              gap: 14px;
              .flightTime {
                color: #696969;
                font-size: 8px;
                line-height: 15px;
                letter-spacing: 0.2px;
                font-weight: 600;
                font-family: lato;
                .supText {
                  color: #bf150d;
                  margin-left: 1px;
                }
                .flightName {
                  font-weight: 500 !important;
                }
              }
              .stops {
                color: #696969;
                font-family: Lato;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 0.2px;
                line-height: 12px;
                // margin-left: 15px;
              }
            }
          }
        }

        .card3 {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 80px;
          .flightBtn {
            color: #696969;
            font-size: 6px;
            line-height: 8px;
            font-weight: 600;
            font-family: Lato;
            .expIcon {
              width: auto;
              height: 10px;
              cursor: pointer;
            }
          }
          .flightBtn:hover {
            background-color: transparent !important;
          }
        }
        .card4 {
          width: 100px;
          // min-width: 100px !important;
          // max-width: 200px;
          height: 100%;
          background-color: #1d75bb;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 20px;
          border-top-right-radius: 2.6px;
          border-bottom-right-radius: 2.6px;
          .radioBtn {
            position: relative;
            // width: 55px;
            .radioControl {
              .radioGroup {
                .customRadio {
                  border: 1px solid #696969 !important;
                  background-color: #ffffff;
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                  &.Mui-checked {
                    color: #0b3353;
                  }
                  color: #0b3353;
                  .MuiSvgIcon-root {
                    font-size: 10px;
                  }
                }
                .radio {
                  color: #ffffff;
                  font-family: Lato;
                  font-size: 13.33px;
                  font-weight: bold;
                  letter-spacing: 0.3px;
                  line-height: 14.6px;
                }
              }
            }
          }
          .planeBedCard {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            width: 100px;
            .planeBed {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 8px;
              width: 80px;
              // width: 100%;
              .plane {
                height: 13.3px;
                width: 15px;
              }
              .planDesc {
                color: #ffffff;
                font-family: Lato;
                font-size: 7px;
                font-weight: bold;
                letter-spacing: 0.2px;
                line-height: 14px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #696969;
  background-color: #ffffff;
  outline-offset: 2px;
  outline: none;
}

.checkedIcon {
  // outline: 1px solid #696969;
  // outline-offset: 0px;
  // border: 2px solid #ffffff;
  // height: 20px;
  // width: 20px;
  // border-radius: 50%;
  // // background-color: #ffff;

  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #696969;
  background-color: #ffffff;
  outline-offset: 2px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  .selected {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1.5px solid #ffffff;
    background-color: #0B3353;
    outline-offset: 0px;
    outline: none;
  }
}
