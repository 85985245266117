.button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: max-content;
    font-size: 10px !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #696969 !important;
    font-family: "Lato", sans-serif !important;
  }
  
  .iconButton {
    width: 24px;
  }