.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
    height: 100%;
    .contentCard {
      width: 100%;
      // @media only screen and (min-width: 767px) {
      //   width: max-content;
      // }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .hotelListCard {
        width: 100%;
        height: 80px;
        border-radius: 2.6px;
        border-top: 1px solid #979797;
        border-bottom: 1px solid #979797;
        border-left: 1px solid #979797;
        border-right: none;
        display: flex;
        background-color: #ffff;
        // gap: 10px;
        overflow-x: auto;
        scrollbar-width: thin;
        margin-bottom: 8px;
        overflow-y: hidden;
        .card1 {
          width: 100%;
          height: 100%;
          padding: 6px 5px;
          // background-color: #f9e9be;
          //   padding: 20px 10px;
          // padding-left: 6px;
          display: flex;
          // align-items: center;
          gap: 14px;
          // width: 180px;
          .imgCard {
            width: 66px;
            height: 100%;
            .flightImage {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border: 0.51px solid #979797;
              border-radius: 8px;
              box-sizing: border-box;
            }
          }

          .flightTime {
            color: #696969;
            font-size: 8px;
            line-height: 15px;
            letter-spacing: 0.2px;
            font-weight: 600;
            font-family: lato;
            .supText {
              color: #bf150d;
              margin-left: 2px;
            }
            .flightName {
              font-weight: 500 !important;
            }
          }
        }
        .card2 {
          padding: 1.2vh 0px;
          width: 60px;
          .stops {
            color: #696969;
            font-family: Lato;
            font-size: 8px;
            line-height: 12px;
            letter-spacing: 0.2px;
            font-weight: 600;
            margin-bottom: 5px;
            .stopInfo {
              font-weight: 500;
            }
          }
        }
        .card3 {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 80px;
          .flightBtn {
            font-family: Lato;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: none;
            .viewDetails {
              color: #696969;
              font-family: Lato;
              font-size: 6.6px;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: 8px;
              text-align: center;
            }
            .iconAndText {
              display: flex;
              flex-direction: column;
              align-items: center;
              .expIcon {
                width: 14px;
                height: 10px;
                cursor: pointer;
                margin-bottom: 5px;
              }
            }
          }
          .flightBtn:hover {
            background-color: transparent !important;
          }
        }
        .card4 {
          width: 150px;
          // min-width: 100px !important;
          // max-width: 200px;
          height: 100%;
          background-color: #1d75bb;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          // gap: 8px;
          border-top-right-radius: 2.6px;
          border-bottom-right-radius: 2.6px;
          .price {
            color: #ffffff;
            font-family: Lato;
            font-size: 13.3px;
            letter-spacing: 0.4px;
            line-height: 16px;
            text-align: center;
          }
          .flightClass {
            color: #ffffff;
            font-family: lato;
            font-size: 6.6px !important;
            letter-spacing: 0.2px !important;
            line-height: 14.5px !important;
            text-align: center;
            margin-top: -2px !important;
            text-transform: capitalize;
          }
          .cancellation {
            color: #ffffff;
            font-family: Lato;
            font-size: 6.6px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 8px;
            text-transform: capitalize;
            margin-bottom: 5px;
          }
          .bookBtn {
            width: 62px;
            height: 20px;
            border-radius: 30px;
            background-color: #fcb400;
            box-shadow: none;
            border: none;
            outline: none;
            font-size: 8px;
            line-height: 12px;
            // font-size: 6px;
            // line-height: 10px;
            text-align: center;
            color: #222222;
            font-family: Lato;
            font-weight: 600;
            letter-spacing: 0.5px;
            font-family: lato;
          }
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
