.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 90dvh;
    overflow: hidden;
    .logoCard {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: hidden;
      .logo {
        height: 100px;
        width: auto;
        margin-top: -100px;
      }
    }
    .inputCard {
      width: 100%;
      .inputContainer {
        position: fixed;
        bottom: 0;
        left: 0px;
        right: 0px;
        padding: 0 10px;
        padding-top: 15px;
        background-color: #fafafa;
        z-index: 9;
        .contentContainer {
          flex-shrink: 0;
          width: 100%;
          justify-self: center;
          padding: 20px;
          padding-top: 0px;
          padding-bottom: 50px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          position: relative;
          overflow: hidden;

          .infoText {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-self: center;
            align-self: center;
            max-width: 340px;
            position: relative;
            .systemChatIcon {
              display: flex;
              gap: 6px;
              align-items: center;
            }

            .infoIcon {
              width: 15px;
              position: absolute;
              right: -8px;
              bottom: 8px;
            }
          }

          .chatLoaderContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            .chatLoaderSubContainer {
              display: flex;
              flex-direction: column;
              max-width: 380px;
              width: 100%;
              margin-left: -50px;
              margin-right: -50px;
            }
          }
        }
      }
      .sectionContainer {
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;
        .systemChatIcon {
          display: flex;
          gap: 6px;
          align-items: center;
          .chatIcon {
            width: 32px;
          }
        }
      }
    }
  }
  .constText {
    // width: 92.48%;
    color: #222222;
    font-family: Lato;
    font-size: 12px;
    font-style: italic;
    align-self: flex-start;
  }

  .constText h1 {
    padding-left: 50px;
    font-size: 12px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    // margin-left: -15px;
    // margin-right: -30px;
  }
  .constText p {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 11px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    text-align: center;
    // margin-left: -15px;
    // margin-right: -30px;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
