.main {
  margin: 0;
  padding: 0;
  .container {
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    .headerCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .imgCard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        height: 100%;
        .imgTag {
          width: auto;
          height: 24px;
        }
        .title {
          font-size: 14px;
          font-family: Lato;
          letter-spacing: 0.2px;
          line-height: 16px;
          color: #222222;
          font-weight: 600;
          text-align: left;
        }
      }
      .selectPac {
        color: #696969;
        font-family: Lato;
        font-size: 8px;
        letter-spacing: 0.2px;
        line-height: 10px;
        text-align: center;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
