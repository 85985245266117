.headerContainer {
  z-index: 10;
  position: fixed;
  top: 0;
}

.contentContainer {
  background-color: #fafafa;
  @media screen and (max-width: 700px) {
    padding-top: 60px;
  }
}

.dialogMainContainer{
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    .overlay{
        z-index: 1;
        opacity: 0.7;
        background-color: #000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    }
}