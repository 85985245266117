.main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0px;
  gap: 14px;
  width: 90%;
  .hotelImg {
    width: 66px;
    height: 100%;
    border: 0.51px solid #979797;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .packageImg {
    width: 40px;
    height: 40px;
    border: 0.51px solid #979797;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .contentCard {
    position: relative;
    display: block;
    margin-top: -8px;
    // min-width: 130px;
    // max-width: 100%;
    .star {
      color: #272727;
      font-size: 6px;
      line-height: 8px;
      text-align: left;
      font-family: lato;
      font-weight: 500;
    }
    .hotelName {
      color: #252525;
      font-family: lato;
      font-size: 10px;
      letter-spacing: 0.64px;
      line-height: 14px;
      font-weight: bold;
      letter-spacing: 0.2px;
      margin-top: -2px;
      // width: 130px;
    }
    .location {
      color: #696969;
      font-family: Lato;
      font-size: 7px;
      letter-spacing: 0.64px;
      line-height: 14px;
      margin-top: -4px;
      margin-bottom: 5px;
    }
    .amenities {
      color: #696969;
      font-family: Lato;
      font-size: 6.6px;
      letter-spacing: 0.2px;
      line-height: 8px;
    }
  }
}
