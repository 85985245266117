.mainContainer {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  padding: 3px 20px !important;
}
.summary{
  margin: 0px -20px !important;
  padding: 0px 35px !important;
  color: #272727 !important;
  background-color: #F4F4F4 !important;
}
.expandIcon{
  width: 15px;
}
