
.mainContainer{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.heading {
  color: #222222;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

