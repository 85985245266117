.aboutPropertyContainer {
  .aboutRow {
    display: flex;
    flex-direction: column;
    gap: 14px;
    gap: 2.5vw;
    margin-top: 14px;
    margin-top: 3.09vw;

    // font common styles
    color: #272727;
    font-family: Lato;
    text-align: left;

    @media screen and (min-width: 768px) {
      gap: 15px;
      margin-top: 14px;
    }

    .heading {
      font-size: 36px;
      font-size: 2.804vw;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      line-height: 3.115vw;

      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .description {
      font-size: 33px;
      font-size: 2.57vw;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      line-height: 3.115vw;

      @media screen and (min-width: 768px) {
        font-size: 14px;
        letter-spacing: 0.01px;
        line-height: 17px;
      }
    }
  }
}
