.superContainer {
  // margin-top: 30dvh;
  padding-top: 20px;
  position: relative;
  display: flex;
  // min-height: calc(100dvh - 30dvh);
  min-height: calc(100dvh - 40px);
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-direction: column;
  // overflow-x: hidden;
  padding-bottom: 100px;
}

.logo {
  width: 150px;
}

.midSectionContainer {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.horizontalScrollContainer {
  height: 250px;
}

.recommendedTripsContainer {
  width: 500px;
  position: relative;

  @media screen and (max-width: 500px) {
    width: 100vw;
  }

  overflow: hidden;

  .recommendedTripsHeading {
    font-size: 16px;
    font-weight: 800;
    padding-left: 30px;
    padding-bottom: 0px;
    // color: #47174c;
    color: #696969;
  }

  .scrollContainer {
    position: relative;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 5px;
    overflow: auto;
    display: flex;
    gap: 10px;
    // z-index: 0;
    .card {
      display: inline-block;
      white-space: normal;
      animation: slide-left 10s linear infinite;
    }
  }
  .scrollContainer::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @keyframes slide-left {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .rightBtnContainer {
    margin-top: 5px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    // transform: translateY(-50%);
    background-color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0 0 8px;

    .rightBtn {
      width: 32px;
    }
  }

  .leftBtnContainer {
    margin-top: 5px;

    display: flex;
    @media screen and (max-width: 600px) {
      display: block;
    }

    cursor: pointer;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 50%;
    // transform: translateY(-50%);
    background-color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 8px 8px 0;

    .leftBtn {
      width: 32px;
      rotate: 180deg;
    }
  }
}

.contentContainer {
  flex-shrink: 0;
  width: 100%;
  justify-self: center;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;

  .infoText {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-self: center;
    align-self: center;
    max-width: 340px;
    position: relative;
    .systemChatIcon {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .infoIcon {
      width: 15px;
      position: absolute;
      right: -8px;
      bottom: 8px;
    }
  }

  .chatLoaderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .chatLoaderSubContainer {
      display: flex;
      flex-direction: column;
      max-width: 380px;
      width: 100%;
      margin-left: -50px;
      margin-right: -50px;
    }
  }
}

.zenvoyaText {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}

.inputContainer {
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
  padding: 0 10px;
  // padding-top: 15px;
  background-color: #fafafa;
  z-index: 9;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  .systemChatIcon {
    display: flex;
    gap: 6px;
    align-items: center;
    .chatIcon {
      width: 32px;
    }
  }
}

.heading {
  color: #222222;
  font-size: 13px;
}
.constText {
  // width: 92.48%;
  color: #222222;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  align-self: flex-start;
}

.constText h1 {
  padding-left: 40px;
  font-size: 13px;
  color: #222222;
  font-style: normal;
  font-family: Lato;
  // margin-left: -15px;
  // margin-right: -30px;
}
.constText p {
  padding-left: 40px;
  padding-right: 15px;
  font-size: 11px;
  color: #222222;
  font-style: normal;
  font-family: Lato;
  // margin-left: -15px;
  // margin-right: -30px;
}

.upcomingtripContainer {
  width: 100%;
  .heading {
    margin-left: 30px;
    font-size: 16px;
    font-weight: 800;
    color: #696969;
    margin-bottom: 10px;
  }
  .upcomingtripSubContainer {
    margin: 5px;
    position: relative;
    .bookedDate {
      text-align: end;
      margin-right: 2px;
      margin-top: 4px;
      font-size: 8px;
      color: #696969;
    }
  }
}
