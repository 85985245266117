.main {
  display: flex;
  gap: 5px;
  margin: 0;
  padding: 0;
  .ratingCard {
    width: 19px;
    height: 16px;
    border-radius: 4px;
    background-color: #5856d6;
    display: flex;
    align-items: center;
    justify-content: center;
    .rating {
      color: #ffffff;
      font-family: Lato;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 10px;
    }
  }
  .content {
    display: block;
    position: relative;
    .reviewTitle {
      color: #696969;
      font-family: Lato;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 10px;
    }
    .review {
      color: #696969;
      font-family: Lato;
      font-size: 6.6px;
      letter-spacing: 0.2px;
      line-height: 8px;
      font-weight: 500;
    }
  }
}
