.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .container {
        position: relative;
        display: block;
        padding-left: 2px;
        padding-right: 2px;
        .continue {
            border-radius: 2.6px;
            background-color: #FCB400;
            width: 100%;
            height: 66.66px;
            box-shadow: 0 1px 2.6px 0 rgba(0,0,0,0.5);
            color: #222222;
            font-family: Lato;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 22px;
            text-align: center;
            margin-bottom: 10px;
        }
        .select {
            border-radius: 2.6px;
            background-color: #DFDFDF;
            width: 100%;
            height: 66.66px;
            color: #222222;
            font-family: Lato;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.28px;
            line-height: 16px;
            text-align: center;
        }
    }
}
.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}