.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // height: 300px;
  height: calc(100dvh - 140px);
  padding-bottom: 100px;
  @media screen and (max-width: 700px) {
    padding-bottom: 0;
    height: calc(100dvh - 400px);
  }

  .scrollContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 700px) {
      gap: 20px;
    }
    height: 100%;
    overflow: auto;
  }

  .historyCard,
  .historyBox {
    // max-width: 300px;
    margin-right: 10px;
    @media screen and (min-width: 700px) {
     margin-bottom: 20px;
     margin-right: 0px;
    }
    .titleStyles {
      letter-spacing: 0px;
      color: #272727;
      font-weight: 600;
      font-family: Lato;
    }

    .title {
      font-size: 12px;
      @extend .titleStyles;
      margin-bottom: 10px;
      margin-left: 8px;
      @media screen and (min-width: 700px) {
        font-size: 11px;
        text-transform: none;
        margin-left: 0px;
      }
    }

    .todayContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .desc {
        font-size: 16px;
        @extend .titleStyles;
        margin-left: 8px;
        @media screen and (min-width: 700px) {
          font-size: 10px;
          letter-spacing: 0.1px;
          cursor: pointer;
          color: #222222;
          opacity: 0.9;
          margin-left: 0px;
        }
      }
    }
  }
  .divider {
    display: none;
    @media screen and (max-width: 700px) {
      border-bottom: 1px solid #979797;
      width: 90%;
    }
  }
  .historyBox {
    border-bottom: none;
    margin-top: 2vh;
  }
}

.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.noData {
  width: 100%;
  text-align: center;
}
