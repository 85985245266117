.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  user-select: none;
  // background-color: blue;
  // padding-right: 28px;
  // margin-right: -19px;
  flex-grow: 1;
}

.rowContainer {
  display: flex;
  // gap: 25px;
  background-color: #fafafa;
  user-select: none;
}

.cardContainer {
  // background-color: blue;
  // z-index: 1;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    cursor: default;
  }
  user-select: none;
  // flex-grow: 1;
  width: 100%;
  position: relative;
  border: 1px solid rgba(206, 200, 200, 0.1);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1.2px;
  
  height: 66px;
  .leftContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    user-select: none;
    .imageContainer {
      overflow: hidden;
      height: 65px;
      width: 65px;
      border-radius: 3px 0 0 3px;
      user-select: none;
      .imgTag {
        object-fit: cover;
        height: 65px;
        width: 65px;
        user-select: none;
      }
    }
    .cityName {
      font-size: 11px;
      line-height: 16px;
      color: #222222;
      font-family: Lato;
      font-weight: 700 !important;
      text-transform: capitalize;
      user-select: none;
    }
  }
  .checkbox {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 5px;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    user-select: none;
  }
}

.dragIconContainer {
  // border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 0px;
  margin-right: -19px;
  height: 65px;
  // padding: 0 20px;
  width: 80px;
  align-self: flex-end;
  cursor: move;
  .dragTag {
    user-select: none;
    width: 12px;
    @media screen and (max-width: 600px) {
    }
  }
}

.opacityContainer{
  position: absolute;
  background-color: #fff;
  top:-2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  opacity: 0;
  // z-index: 1;
  z-index: 0;
}

.stopText{
  font-size: 11px;
}