.mainContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  gap: 24px;
}

.heading {
  padding-top: 32px;
  padding-left: 32px;
  color: #272727;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  gap: 16px;
}

.imageInputContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 16px;
  .avatar {
    width: 60px;
    height: 60px;
  }
  .uploadRemoveButtonText{
    font-size: 12px;
    color: #272727;
    font-family: 'Lato', sans-serif;
    font-weight: 510;
    cursor: pointer;
  }
}

.inputLabelContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    .labelText{
        font-size: 11px;
        margin-bottom: 5px;
    }
}

.button{
    box-shadow: none !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: initial !important;
    width: 100% !important;
    height: 70px !important;
    border-radius: 0px !important;
}