.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  .container {
    background-color: #fff;
    width: 100%;
    height: 100vh;
    overflow: auto;
    scrollbar-width: thin;
    .itineraryCard {
      padding-left: 30px;
      padding-right: 20px;
      padding-bottom: 30px;
      .dayCard {
        display: block;
        position: relative;
        margin-top: 60px;
        margin-bottom: 28px;
        .today {
          color: #696969;
          font-family: Lato;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.4px;
          line-height: 22px;
          text-transform: capitalize;
          margin-bottom: 18px;
        }
        .day {
          text-align: left;
          color: #272727;
          font-family: Lato;
          font-size: 8.3px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 12px;
        }
        .explore {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 14px;
        }
      }
      .dayWiseItineraryCard {
        box-sizing: border-box;
        height: 70px;
        width: 100%;
        border: 1px solid #efefef;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 0.6px 3.1px 0 rgba(0, 0, 0, 0.5);
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 20px;
        margin-bottom: 10px;
        .morning {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          font-weight: bold;
        }
        .description {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          text-align: left;
          margin-top: 0px !important;
          .highlightText {
            color: #007aff;
          }
        }
      }
    }
    .divider {
      width: 100%;
      border-bottom: 1px solid #696969;
    }
    .nearByPlaces {
      padding-left: 20px;
      padding-top: 26px;
      padding-bottom: 10px;
      .restaurants {
        color: #272727;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        text-transform: capitalize;
      }
      .specCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 14px;
        .specs {
          padding-top: 11px;
          padding-left: 20px;
          .specTitle {
            color: #007aff;
            font-family: Lato;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
          }
      
          .specDesc {
            color: #272727;
            font-family: Lato;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
          }
        }
        .distance {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 12px;
        }
      }
    }
    .exploreCard {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      .exploreBtn {
        width: 160px;
        height: 30px;
        border-radius: 15px;
        background-color: #205c8c;
        color: #FFFFFF;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 14px;
        text-align: center;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
}
