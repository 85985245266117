.amenitiesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  margin-top: 3.5vw;

  @media screen and (min-width: 768px) {
    margin-top: 3px;
  }

  .amenitiesListContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // padding-left: 50px;
    row-gap: 40px;
    row-gap: 3.115vw;
    column-gap: 115.56px;
    column-gap: 9vw;
    // margin-top: 15px;

    @media screen and (min-width: 768px) {
      row-gap: 15px;
      column-gap: 20px;
    }

    .amenityRow {
      display: flex;
      align-items: center;
      gap: 40px;
      gap: 3.115vw;
      @media screen and (min-width: 768px) {
        gap: 8px;
      }
      .amenityIcon {
        width: 40px;
        width: 3.115vw;

        @media screen and (min-width: 768px) {
          width: 16px;
        }
      }
      .itemName {
        color: #272727;
        font-family: Lato;
        font-size: 30px;
        font-size: 2.336vw;
        letter-spacing: 0.64px;
        letter-spacing: 0.05vw;
        line-height: 50px;
        line-height: 3.894vw;
        @media screen and (min-width: 768px) {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }
  }

  .amenitiesFooter {
    display: flex;
    align-items: center;

    color: #696969;
    font-family: Lato;
    font-size: 30px;
    font-size: 2.336vw;
    letter-spacing: 0.64px;
    letter-spacing: 0.05vw;
    line-height: 36px;
    line-height: 2.804vw;
    text-align: right;
    gap: 23px;
    gap: 1.791vw;

    margin-top: 70px;
    margin-top: 5.452vw;

    @media screen and (min-width: 768px) {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      gap: 5px;
      margin-top: 20px;
    }

    .amenityFooterIcon {
      width: 40px;
      width: 3.115vw;

      @media screen and (min-width: 768px) {
        width: 16px;
      }
    }
  }
}
