.roundTripCard {
  display: block;
  margin-top: 10px;
  .buttonTitleStyles {
    color: #ffffff;
    font-size: 7px;
    line-height: 9px;
    letter-spacing: 0.4px;
    font-weight: 600;
    font-family: lato;
    margin-bottom: 2px;
    text-transform: uppercase;
    margin-left: 2px;
  }
  .buttonStyles {
    height: 30px;
    width: max-content;
    display: flex;
    align-items: center;
    border: 1px solid #979797;
    border-radius: 4px;
    background-color: #f6f6f6;
    padding: 10px 5px;
    color: #222222;
    font-family: Lato;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 10px;
    cursor: pointer;
  }
  .routTitle {
    @extend .buttonTitleStyles;
  }
  .roundBox {
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .buttonStyles;
    text-align: center;
    padding: 10px 10px;
  }
}
