.customTabPanelContainer {
  padding: 0 20px;
}

.imageContainer {
  margin: 0 -18px;
  margin-bottom: 20px;
  // height: 150px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  @media only screen and (min-width: 767px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.tabTextContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  @media screen and (min-width: 700px) {
    padding-left: 20px;
  }

  .tabHeading {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 2px;
    // color: #272727;
  }
  & h1 {
    font-size: 15px;
    font-weight: 800;
  }
  & h2 {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .notSelected {
    color: #c4c4c4;
  }
}

.modifyButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .doneButton:hover{
    background-color: #1f8539;
  }
  .doneButton {
    background-color: #1f8539;
    color: #ffffff !important;
    font-size: 10px;
    font-weight: 500;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-right: -22px;
    border-radius: 6px 0 0 6px;
    padding-top: 7px;
  }
  .doneIcon{
    width: 18px;
    margin-bottom: 3px;
  }
}

.cityDescription {
  padding: 10px 25px 20px 25px;
  color: #272727;
  font-family: Lato, sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;

  @media only screen and (min-width: 767px) {
    margin-left: 10px;
    width: 90%;
  }
}

.timeInADay {
  padding: 10px;
  box-sizing: border-box;
  // border: 1px solid #efefef;
  border-radius: 4px;
  background-color: #ffffff;
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
width: 100%;
  & h1 {
    font-weight: 800;
    color: #272727;
  }
}

.description {
  color: #272727;
  font-size: 12px;
}

.budgetEstimatesContainer {
  margin: 0 -20px;
}

// Heading Component
.headingContainer {
  display: flex;
  gap: 8px;
  margin-left: -20px;
  margin-right: 30px;
  flex-direction: column;
  & h2 {
    font-weight: 800;
    font-size: 12px;
    color: #272727;
    width: 100%;
  }
  .subHeadingContainer {
    display: flex;
    gap: 20px;
    & h1 {
      font-weight: 800;
      font-size: 9px;
      text-transform: uppercase;
      color: #272727;
    }
  }
}

/**
 Editing icon
*/
.activityContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}
.iconButton {
  width: 24px;
  height: 24px;
  margin-right: -5px !important;
  border-radius: 4px !important;
  .editIcon {
    width: 22px;
  }
}


.loaderContainer{
  margin-top: 20px;
  display: none;
  @media screen and (max-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}