.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    position: relative;
    .closeCard {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: 10px;
      margin-bottom: 10px;
      .closeIcon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .detailCard {
      height: 90px;
      width: 100%;
      background-color: #696969;
      padding: 10px 10px 10px 18px;
      .title {
        color: #ffffff;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 14px;
      }
      .inputCard {
        width: 100%;
        height: 100%;
        overflow-x: auto;
        scrollbar-width: thin;
        .inputBox {
          display: flex;
          gap: 5px;
          .buttonTitleStyles {
            color: #ffffff;
            font-size: 7px;
            line-height: 9px;
            letter-spacing: 0.4px;
            font-weight: 600;
            font-family: lato;
            margin-bottom: 2px;
            text-transform: uppercase;
            margin-left: 2px;
          }
          .buttonStyles {
            height: 30px;
            width: max-content;
            display: flex;
            align-items: center;
            border: 1px solid #979797;
            border-radius: 4px;
            background-color: #f6f6f6;
            padding: 10px 5px;
            color: #222222;
            font-family: Lato;
            font-size: 8px;
            font-weight: bold;
            letter-spacing: 0.4px;
            line-height: 10px;
            cursor: pointer;
          }
          .travelCard {
            display: block;
            margin-top: 10px;
            .travelTitle {
              @extend .buttonTitleStyles;
            }
            .travelBtn {
              @extend .buttonStyles;
              .arrowIcon {
                width: 10px;
                height: 10px;
                cursor: pointer;
                margin-left: 5px;
                margin-right: 5px;
              }
            }
            .searchTitle {
              @extend .buttonTitleStyles;
              visibility: hidden;
            }
            .searchBtn {
              background-color: #fcb400 !important;
              @extend .buttonStyles;
              .searchIcon {
                width: 20px;
                height: 20px;
                cursor: pointer;
              }
            }
          }
          .roundTripCard {
            display: block;
            margin-top: 10px;
            .routTitle {
              @extend .buttonTitleStyles;
            }
            .roundBox {
              display: flex;
              align-items: center;
              justify-content: center;
              @extend .buttonStyles;
              text-align: center;
              padding: 10px 10px;
            }
          }
        }
        .inputCard::-webkit-scrollbar {
          height: 8px;
        }
        .inputCard::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        .inputCard::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
    .detailCard::-webkit-scrollbar {
      width: 1px !important;
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
