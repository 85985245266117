.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    .iconBtn,
    .activeIcon {
        padding-left: 10px;
        padding-right: 10px;
      .userName {
        color: #222222;
        font-size: 12px;
        font-weight: 600;
        font-family: lato;
        text-transform: uppercase;
        margin-right: 10px;
      }
      .avatar {
        color: #7cbeea;
        background-color: #ffffff;
        border-color: #7cbeea;
        border: 1px solid #7cbeea;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
    .activeIcon {
      background-color: #F3F3F3;
      border-radius: 4px !important;
      padding-left: 10px;
      padding-right: 10px;
    }
    .iconBtn:hover {
        background-color: #F3F3F3;
        border-radius: 4px !important;
        padding-left: 10px;
        padding-right: 10px;
    }
    .iconBtn::selection {
      background-color: #F3F3F3;
      border-radius: 4px !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .profileContainer {
    position: relative;
    padding: 2vh;
    .emptyCard {
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-right: 35px;
      padding-top: 20px;

      .contentStyles {
        font-family: "Lato", sans-serif;
        color: #222222;
      }

      .userName {
        @extend .contentStyles;
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
      }

      .email {
        @extend .contentStyles;
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
      }

      .phone {
        @extend .contentStyles;
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
      }

      .address {
        @extend .contentStyles;
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
      }
    }
  }
}
.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
